import { Color } from "@thekeytechnology/epic-ui";
import { Recursive, Token } from "@styled-system/types/composition";

/**
 * @deprecated This class should not be used
 */
export class TkaColor extends Color {
	constructor(color: string, alpha: number = 1) {
		super(color, alpha);
	}

	public withAlpha(alpha: number): TkaColor {
		return new TkaColor(this.hexValue(), alpha);
	}

	getColor(alpha?: number): string {
		if (alpha) {
			return this.withAlpha(alpha).toString();
		}

		return this.toString();
	}
}
/**
 * @deprecated This color should not be used
 */
export const brandStrong20: TkaColor = new TkaColor("rgb(212, 250, 235)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong40: TkaColor = new TkaColor("rgb(169, 245, 215)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong80: TkaColor = new TkaColor("rgb(82, 234, 175)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong100: TkaColor = new TkaColor("rgb(39, 229, 155)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong120: TkaColor = new TkaColor("rgb(7, 158, 131)");

/**
 * @deprecated This color should not be used
 */
export const brandMain20: TkaColor = new TkaColor("rgb(230, 229, 239)");
/**
 * @deprecated This color should not be used
 */
export const brandMain40: TkaColor = new TkaColor("rgb(114, 112, 207)");
/**
 * @deprecated This color should not be used
 */
export const brandMain80: TkaColor = new TkaColor("rgb(83, 39, 230)");
/**
 * @deprecated This color should not be used
 */
export const brandMain100: TkaColor = new TkaColor("rgb(41, 19, 113)");

/**
 * @deprecated This color should not be used
 */
export const onBrandMain: TkaColor = new TkaColor("rgb(244, 248, 249)");
/**
 * @deprecated This color should not be used
 */
export const onBrandStrong: TkaColor = new TkaColor("rgb(41, 19, 113)");

/**
 * @deprecated This color should not be used
 */
export const shade0: TkaColor = new TkaColor("rgb(255, 255, 255)");
/**
 * @deprecated This color should not be used
 */
export const shade5: TkaColor = new TkaColor("rgb(245, 246, 247)");
/**
 * @deprecated This color should not be used
 */
export const shade10: TkaColor = new TkaColor("rgb(233, 235, 238)");
/**
 * @deprecated This color should not be used
 */
export const shade20: TkaColor = new TkaColor("rgb(215, 218, 222)");
/**
 * @deprecated This color should not be used
 */
export const shade40: TkaColor = new TkaColor("rgb(166, 174, 185)");
/**
 * @deprecated This color should not be used
 */
export const shade60: TkaColor = new TkaColor("rgb(117, 128, 144)");
/**
 * @deprecated This color should not be used
 */
export const shade80: TkaColor = new TkaColor("rgb(59, 73, 93)");
/**
 * @deprecated This color should not be used
 */
export const shade100: TkaColor = new TkaColor("rgb(14, 23, 35)");

/**
 * @deprecated This color should not be used
 */
export const success0: TkaColor = new TkaColor("rgb(237, 249, 238)");
/**
 * @deprecated This color should not be used
 */
export const success20: TkaColor = new TkaColor("rgb(209, 234, 210)");
/**
 * @deprecated This color should not be used
 */
export const success100: TkaColor = new TkaColor("rgb(24, 152, 29)");

/**
 * @deprecated This color should not be used
 */
export const info0: TkaColor = new TkaColor("rgb(238, 246, 254)");
/**
 * @deprecated This color should not be used
 */
export const info20: TkaColor = new TkaColor("rgb(212, 234, 255)");
/**
 * @deprecated This color should not be used
 */
export const info100: TkaColor = new TkaColor("rgb(39, 149, 254)");

/**
 * @deprecated This color should not be used
 */
export const warning0: TkaColor = new TkaColor("rgb(255, 252, 239)");
/**
 * @deprecated This color should not be used
 */
export const warning20: TkaColor = new TkaColor("rgb(255, 246, 214)");
/**
 * @deprecated This color should not be used
 */
export const warning100: TkaColor = new TkaColor("rgb(224, 168, 39)");

/**
 * @deprecated This color should not be used
 */
export const error0: TkaColor = new TkaColor("rgb(255, 242, 237)");
/**
 * @deprecated This color should not be used
 */
export const error20: TkaColor = new TkaColor("rgb(251, 221, 210)");
/**
 * @deprecated This color should not be used
 */
export const error100: TkaColor = new TkaColor("rgb(234, 86, 28)");

/**
 * @deprecated This color should not be used
 */
export const onBrand: TkaColor = new TkaColor("rgb(24, 66, 118)");

export const colors: Recursive<Token<string>> = {
	primary: {
		20: { value: "rgb(230, 229, 239)" },
		40: { value: "rgb(114, 112, 207)" },
		80: { value: "rgb(83, 39, 230)" },
		100: { value: "rgb(41, 19, 113)" },
	},
	secondary: {
		20: { value: "rgb(212, 250, 235)" },
		40: { value: "rgb(169, 245, 215)" },
		80: { value: "rgb(82, 234, 175)" },
		100: { value: "rgb(39, 229, 155)" },
		120: { value: "rgb(7, 158, 131)" },
	},
	onPrimary: { value: "rgb(244, 248, 249)" },
	onSecondary: { value: "rgb(41, 19, 113)" },
	shade: {
		0: { value: "rgb(255, 255, 255)" },
		5: { value: "rgb(245, 246, 247)" },
		10: { value: "rgb(233, 235, 238)" },
		20: { value: "rgb(215, 218, 222)" },
		40: { value: "rgb(166, 174, 185)" },
		60: { value: "rgb(117, 128, 144)" },
		80: { value: "rgb(59, 73, 93)" },
		100: { value: "rgb(14, 23, 35)" },
	},
	success: {
		0: { value: "rgb(237, 249, 238)" },
		20: { value: "rgb(209, 234, 210)" },
		100: { value: "rgb(24, 152, 29)" },
	},
	info: {
		0: { value: "rgb(238, 246, 254)" },
		20: { value: "rgb(212, 234, 255)" },
		100: { value: "rgb(39, 149, 254)" },
	},
	warning: {
		0: { value: "rgb(255, 252, 239)" },
		20: { value: "rgb(255, 246, 214)" },
		100: { value: "rgb(224, 168, 39)" },
	},
	error: {
		0: { value: "rgb(255, 242, 237)" },
		20: { value: "rgb(251, 221, 210)" },
		100: { value: "rgb(234, 86, 28)" },
	},
	transparent: { value: "rgba(0, 0, 0, 0)" },
};
