import { gtmTrackViewCourseDetail } from "@thekeytechnology/academies-lib-webapp/analytics";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { useCurrentPath } from "@hooks/use-current-path";
import { root_GetRootQuery } from "@relay/root_GetRootQuery.graphql";
import { BranchNodesPath, Path } from "@router/index";
import { TkaB1Span, TkaH1Span } from "@themes/font-tags";
import { BranchNodeTab } from "./branch-nodes-tab";
import { RootContainerId } from "./root-overview/root-overview.const";
import { RootScreenContext } from "./root.context";
import { QUERY } from "./root.graphql";
import { RootScreenSkeleton } from "./root.skeleton";
import {
	LicenseWrapper,
	NeedsLicenseWrapper,
	PaddingWrapper,
	ReceivableGamificationPointsWrapper,
	SideMenuWrapper,
	StickyWrapper,
	SubpathBackground,
	Wrapper,
} from "./root.styles";
import { RootLocationState, RootScreenProps } from "./root.types";
import { getTabIndexForPath, useRootNavigation } from "./root.util";
import { ReactComponent as KeyMedalSvg } from "../../assets/key-medal-points.svg";
import { CertificateCard } from "../../components/certificate-card";
import { RootProgress } from "../../components/root-progress";
import { RouterContentMenu } from "../../components/router-content-menu";
import { ScreenWithNavbarSidemenuLayout } from "../../layouts/screen-with-navbar-sidemenu-layout";

export const RootScreenWithRootId = ({ rootId }: RootScreenProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const theme = useTheme();

	const navigate = useNavigate();
	const { state }: RootLocationState = useLocation();
	const currentPath = useCurrentPath();
	const { navigateToTab } = useRootNavigation();
	const { isXLargeUp } = useWindowSize();

	const [activeIndex, setActiveIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const query = useLazyLoadQuery<root_GetRootQuery>(
		QUERY,
		{ id: rootId },
		{ fetchPolicy: "store-and-network" },
	);

	const isRootPath = currentPath?.route.path === Path.root.withIdPlaceholder().path;
	const isRootOverviewPath = currentPath?.route.path === "overview";

	useEffect(() => {
		if (isRootPath) {
			if (
				query?.node?.root?.structureDefinition?.extension?.product &&
				query?.node?.root?.structureDefinition.extension?.unlockInfo?.kind !== "FullAccess"
			) {
				const product = query?.node?.root?.structureDefinition?.extension?.product;
				gtmTrackViewCourseDetail(product.id, product?.title, state?.productIds ?? []);
			}
			navigate(Path.root.withId(rootId).overview.path, { replace: true });
		}
	}, [isRootPath]);

	const isContentBasePath = currentPath?.route.path === BranchNodesPath.pathName;

	useEffect(() => {
		if (isContentBasePath) {
			const nextContentId =
				query.node?.root?.structureDefinition.viewerTreeState?.headContentId ??
				query.node?.root?.typeDefinition?.children?.[0]?.typeDefinition.children?.[0]?.id;
			nextContentId &&
				navigate(Path.root.withId(rootId).branchNodes.withId(nextContentId).path, {
					replace: true,
				});
		}
	}, [isContentBasePath]);

	useEffect(() => {
		const pathIndex = getTabIndexForPath(currentPath?.route.path);
		if (pathIndex !== activeIndex) {
			setActiveIndex(pathIndex);
		}
	}, [currentPath?.route.path, activeIndex]);

	const handleOnTabChange = useCallback(
		(params: TabViewTabChangeEvent) => {
			navigateToTab(params.index);
		},
		[navigateToTab],
	);

	const handleOnCartClickout = useCallback(() => {
		const cartLink =
			query.node?.root?.structureDefinition?.extension?.cartClickout?.relativeLink;
		cartLink && navigate(cartLink);
	}, [query, navigate]);

	const rootName = query.node?.root?.structureDefinition?.title;
	const isIHK = query?.node?.root?.structureDefinition?.extension?.reducedData?.isIHK;
	const needLicense =
		query?.node?.root?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const availableLicensesCount = 0; // query?.node?.root?.structureDefinition?.extension?.licenseAvailability?.numAvailable ?? 0;
	const receivableGamificationPoints =
		query?.node?.root?.structureDefinition?.extension?.reducedData
			?.receivableGamificationPoints;
	// const hasTrailer = !!query?.node?.root?.structureDefinition?.extension?.trailer?.url;
	// const hasGoals =
	// 	(query?.node?.root?.structureDefinition?.extension?.learnGoals?.length ?? 0) > 0;
	// const hasInstructors = (query?.node?.root?.instructorsV2.edges?.length ?? 0) > 0;
	const canOrderIHK =
		query?.node?.root?.structureDefinition?.extension?.ihkState === "ReadyToOrder";

	const tabBar = (
		<TabBar activeIndex={activeIndex} onTabChange={handleOnTabChange}>
			<TabPanel header={t("course_screen.tabOverview")} />
			<TabPanel header={t("course_screen.tabModule")} />
			<TabPanel header={t("course_screen.tabMaterial")} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noPadding
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			contentContainerId={RootContainerId}
			sideMenu={
				<SideMenuWrapper>
					<RouterContentMenu
						items={[
							{
								id: "overview",
								label: t("course_screen.tabOverview"),
							},
							{
								id: "modules",
								label: t("course_screen.tabModule"),
								children: query.node?.root && (
									<BranchNodeTab
										learnOpportunityV2FragmentRef={query.node.root}
									/>
								),
							},
							{
								id: "materials",
								label: t("course_screen.tabMaterial"),
								// children: query.node?.root && (
								// 	<MaterialsTab learnOpportunityV2FragmentRef={query.node.root} />
								// ),
							},
						]}
					/>
					{((isIHK && rootName) || needLicense) && (
						<LicenseWrapper>
							{((isIHK && rootName) || receivableGamificationPoints) && (
								<TkaB1Span tkaColor={theme.colors.shade80} bold>
									Was du erhältst:
								</TkaB1Span>
							)}
							{isIHK && rootName && (
								<CertificateCard
									rootName={rootName}
									canOrder={canOrderIHK}
									rootId={rootId}
								/>
							)}
							{receivableGamificationPoints && (
								<ReceivableGamificationPointsWrapper>
									<TkaH1Span tkaColor={theme.colors.warning100} bold>
										+{receivableGamificationPoints}
									</TkaH1Span>
									<KeyMedalSvg width={22} height={22} />
								</ReceivableGamificationPointsWrapper>
							)}
							{needLicense && availableLicensesCount === 0 && (
								<NeedsLicenseWrapper>
									<TkaB1Span tkaColor={theme.colors.shade80} bold>
										Keine Lizenz im Besitz
									</TkaB1Span>
									<Button
										label="Lizenz erwerben"
										colorVersion="outline"
										className="w-full"
										iconName="shoppingBasket"
										onClick={handleOnCartClickout}
									/>
								</NeedsLicenseWrapper>
							)}
							{!needLicense && availableLicensesCount > 0 && (
								<div>
									<TkaB1Span tkaColor={theme.colors.shade80} bold>
										{availableLicensesCount} Lizenzen verfügbar
									</TkaB1Span>
									<Button
										label="Lizenz erwerben"
										colorVersion="outline"
										className="w-full"
										iconName="shoppingBasket"
										onClick={handleOnCartClickout}
									/>
								</div>
							)}
						</LicenseWrapper>
					)}
				</SideMenuWrapper>
				// 	<SideMenuHeaderWrapper>{tabBar}</SideMenuHeaderWrapper>
				// 	<TabBar
				// 		hideHeader
				// 		renderActiveOnly={false}
				// 		activeIndex={activeIndex}
				// 		onTabChange={() => {
				// 			//ignore just required from primereact to work with activeIndex
				// 		}}
				// 	>
				// 		<TabPanel>
				// 			<RootOverviewTab
				// 				rootId={rootId}
				// 				rootName={rootName}
				// 				hasIHKCertificate={isIHK}
				// 				needsLicense={needLicense}
				// 				hasTrailer={hasTrailer}
				// 				hasGoals={hasGoals}
				// 				hasInstructors={hasInstructors}
				// 				canOrderIHK={canOrderIHK}
				// 			/>
				// 		</TabPanel>
				// 		<TabPanel>

				// 		</TabPanel>
				// 		<TabPanel>
				// 			{query.node?.root && (
				// 				<MaterialsTab learnOpportunityV2FragmentRef={query.node.root} />
				// 			)}
				// 		</TabPanel>
				// 	</TabBar>
			}
			bottomContent={<AdvanceTreeNavigation />}
		>
			<Wrapper>
				<PaddingWrapper>
					{query.node?.root && (
						<RootProgress
							hideImage={!isRootOverviewPath}
							learnOpportunityV2FragmentRef={query.node.root}
						/>
					)}
					{!isXLargeUp && <StickyWrapper>{tabBar}</StickyWrapper>}
				</PaddingWrapper>
				{!isRootOverviewPath ? (
					<SubpathBackground>
						<Outlet />
					</SubpathBackground>
				) : (
					<PaddingWrapper>
						<Outlet />
					</PaddingWrapper>
				)}
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};

export const RootScreen = () => {
	const { rootId } = useParams();
	const [spyEnabled, setSpyEnabled] = useState(false);
	const [lastContentId, setLastContentId] = useState<string>();

	return rootId ? (
		<RootScreenContext.Provider
			value={{ spyEnabled, setSpyEnabled, lastContentId, setLastContentId }}
		>
			<Suspense fallback={<RootScreenSkeleton />}>
				<RootScreenWithRootId rootId={rootId} />
			</Suspense>
		</RootScreenContext.Provider>
	) : null;
};
