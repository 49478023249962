import { css } from "@styled-system/css";

export const header = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
	height: "full",
});

export const headerInnerClass = css({
	display: "flex",
	justifyContent: "space-between",
	gap: "24",
});

export const headerFilterWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const bottonBarWrapper = css({
	display: "flex",
	justifyContent: "flex-end",
	gap: "8",
	padding: "16",
});

export const noGrowWrapperClass = css({
	flexGrow: "0",
	lg: {
		width: "[11rem]",
	},
});
