import { graphql } from "react-relay";

export const PUBLISHED_COURSE_LEARNABLE_FRAGMENT = graphql`
	fragment educationalOfferCard_PublishedCourseLearnableFragment on PublishedLearnable {
		... on PublishedCourseLearnable {
			id
			kind
			root {
				id
				description
				shortDescription
				image {
					url
				}
				typeDefinition {
					... on LearnAsyncContentTypeDefinition {
						extension {
							... on LearnContentExtensionImpl {
								isUnlockNecessaryToStart
							}
						}
					}
				}
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						progressPercentage
						title
						viewerTreeState {
							kind
							... on IsStartedViewerTreeState {
								headContentId
							}
						}
						extension {
							... on PublishedRootExtensionImpl {
								unlockInfo {
									kind
									... on UnlockInfoDemo {
										firstContentId
									}
								}
								diploma
								tags {
									id
									kind
									data {
										name
										isClickable
										isTopic
									}
								}
								# licenseAvailability {
								# 	... on LicenseAvailabilityFreeLicensesAvailable {
								# 		numAvailable
								# 	}
								# }
								product {
									extensions {
										kind
										... on AcademiesProductExtension {
											deGrossPrice
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
