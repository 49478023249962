import { graphql } from "react-relay";

export const QUERY = graphql`
	query profileMenu_Query {
		Viewer {
			Auth {
				...profileMenu_AuthViewerSchemaFragment
			}
		}
	}
`;

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment profileMenu_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			user {
				name
				extension(userExtensionKind: Academies) {
					... on AcademiesUserExtension {
						avatar {
							url
						}
						sumGamificationPoints
					}
				}
				...userAvatar_UserFragment
			}
			accounts {
				id
			}
			...accountsDropdown_CurrentUserFragment
		}
	}
`;
