import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { H1Span } from "@thekeytechnology/epic-ui";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { bottonBarWrapper, header } from "./notes.styles";
import { NotesTabbarSkeleton } from "./parts/notes-tabbar/notes-tabbar.skeleton";

export const NotesScreenSkeleton = () => {
	return (
		<ScreenWithProfileMenuLayout
			bottomContent={
				<div className={bottonBarWrapper}>
					<Button label="Neue Notiz" iconName="add" />
				</div>
			}
		>
			<div className={header}>
				<H1Span>Notizen</H1Span>
				<NotesTabbarSkeleton />
			</div>
		</ScreenWithProfileMenuLayout>
	);
};
