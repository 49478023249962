import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
    flex
    flex-col
    
`;

export const PaddingWrapper = tw.div`
    px-32
    pt-32
    pb-16
`;

export const HeaderWrapper = tw.div`
    flex
    flex-col
    gap-24
`;

export const SideMenuWrapper = tw.div`
    h-full
    flex
    flex-col
    gap-32
    overflow-hidden
`;

export const SideMenuHeaderWrapper = tw.div`
    flex
    flex-col
    gap-24
    px-24
`;

export const LicenseWrapper = tw.div`
    flex
    flex-col
    gap-8
    px-24
`;

export const StickyWrapper = tw.div`
    sticky
    top-0
    z-20
    bg-shade-0
`;

export const SubpathBackground = styled.div`
	background-color: ${({ theme }) => theme.colors.shade0};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius24}
		${({ theme }) => theme.borderRadius?.borderRadius24} 0 0;
	height: 100%;
	padding: ${({ theme }) => theme.spacing.spacing40.rem()}
		${({ theme }) => theme.spacing.spacing40.rem()} 0;
	box-shadow: 0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02),
		0px -85px 0px 0px rgba(41, 19, 113, 0.11), 0px -7px 0px 0px rgba(41, 19, 113, 0.11);
`;

export const ReceivableGamificationPointsWrapper = tw.div`
    flex
    justify-center
    items-center
    gap-8
    w-full
    py-12
    bg-shade-0
    rounded-12
`;

export const NeedsLicenseWrapper = tw.div`
    flex
    flex-col
    gap-8
    mt-32
`;
