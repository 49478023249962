import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	position: "absolute",
	left: "0",
	right: "0",
	bottom: "0",
	direction: "column",
	align: "flex-end",
	gap: "8",
	bg: "shade.0",
	boxShadow: "card",
	width: "full",
	borderTopRadius: "12",
	lg: {
		left: "50%",
		bottom: "24",
		width: "[calc(100% - 24px - 24px)]",
		transform: "translateX(-50%)",
		borderRadius: "24",
	},
});

export const modalsWrapperClass = flex({
	position: "absolute",
	top: "0",
	left: "0",
	right: "0",
	zIndex: "100",
	align: "center",
	transform: "translateY(calc(-100% - 4px))",
	pt: "12",
	pb: "4",
	overflow: "hidden",
});

export const progressWrapperClass = flex({
	position: "absolute",
	zIndex: "10",
	height: "1/2",
	width: "full",
	left: "50%",
	top: "-4",
	roundedTop: "16",
	transform: "translateX(-50%)",
	bg: "shade.20",
	overflow: "hidden",
	lg: {
		roundedTop: "[28px]",
		roundedBottom: "[3px]",
		width: "[calc(100%+8px)]",
	},
});

export const progressClass = css({
	position: "absolute",
	bg: "secondary.100",
	height: "full",
	transition: "[width 0.5s]",
	width: "var(--percentage)",
});

export const contentWrapperClass = flex({
	justify: "flex-end",
	zIndex: "12",
	width: "full",
	height: "full",
	p: "16",
	bg: "shade.0",
	borderTopRadius: "12",
	lg: {
		borderRadius: "24",
	},
});

export const buttonsWrapperClass = flex({
	justify: "flex-end",
	gap: "16",
});
