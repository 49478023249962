import ReactGridCarousel from "react-grid-carousel";
import { CarouselProps } from "@components/carousel/carousel.types";
import React, { PropsWithChildren } from "react";
import { Dot } from "@components/carousel/parts/dot";

export const Carousel = ({
	gap,
	dotColorInactive,
	dotColorActive,
	showDots,
	loop,
	rows,
	cols,
	dot,
	hideArrow,
	arrowLeft,
	arrowRight,
	children,
}: PropsWithChildren<CarouselProps>) => {
	return (
		<ReactGridCarousel
			gap={gap ? gap?.remValue() * 16 : 0}
			cols={cols}
			rows={rows}
			loop={loop}
			showDots={showDots}
			dotColorActive={dotColorActive?.getColor()}
			dotColorInactive={dotColorInactive?.getColor()}
			containerClassName={"mycarousel"}
			hideArrow={hideArrow}
			arrowLeft={arrowLeft}
			arrowRight={arrowRight}
			dot={dot ?? Dot}
		>
			{React.Children.map(children, (child, i) => (
				<ReactGridCarousel.Item key={"carousel-item-i" + i}>{child}</ReactGridCarousel.Item>
			))}
		</ReactGridCarousel>
	);
};
