import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useState } from "react";
import { useFragment } from "react-relay";
import { PersologEvaluationClassModal } from "@components/persolog-evaluation-class-modal";
import { Persolog_ClassType } from "@relay/persologEvaluationElement_ContentSubmissionFragment.graphql";
import { H1Span, P1Span, P2Span } from "@themes/font-tags";
import { CONTENT_SUBMISSION_FRAGMENT } from "./persolog-evaluation-element.graphql";
import {
	elementWrapperClass,
	hrClass,
	resultBarClass,
	resultBarWrapperClass,
	resultItemClass,
	resultListClass,
	resultTextClass,
} from "./persolog-evaluation-element.styles";
import {
	ClassPercentageStyle,
	PersologEvaluationElementProps,
} from "./persolog-evaluation-element.types";

export const PersologEvaluationElement = ({
	contentSubmissionFragmentRef,
}: PersologEvaluationElementProps) => {
	const contentSubmission = useFragment(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [modalPageClassType, setModalPageClassType] = useState<Persolog_ClassType>("D");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;

	const evaluationClasses = element?.evaluation?.classes;
	const classDefinitions = element?.classes;

	const classes =
		evaluationClasses?.map((ec) => {
			const classDefinition = classDefinitions?.find((cd) => cd.classType === ec.classType);
			return {
				...classDefinition,
				percentage: ec.percentage,
			};
		}) ?? [];

	const createHandleClassOnClick = (classType: Persolog_ClassType) => () => {
		setIsModalVisible(true);
		setModalPageClassType(classType);
	};

	const handleModalOnDismiss = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<SideMenuContentPadding className={elementWrapperClass}>
				<H1Span>Dein Ergebnis</H1Span>
				<P1Span></P1Span>
				<hr className={hrClass} />
				<div className={resultListClass}>
					{classes.map((c) => (
						<button
							key={c.classType}
							type="button"
							onClick={createHandleClassOnClick(c.classType ?? "D")}
							className={resultItemClass}
						>
							<div className={resultBarWrapperClass({ classType: c.classType })}>
								<div
									className={resultBarClass({ classType: c.classType })}
									style={
										{
											"--percentage": `${c.percentage}%`,
										} as ClassPercentageStyle
									}
								/>
							</div>
							<P2Span className={resultTextClass({ classType: c.classType })}>
								<span>{stripHtml(c.title ?? "")}</span>
								<br />
								<span>{c.percentage}%</span>
							</P2Span>
						</button>
					))}
				</div>
			</SideMenuContentPadding>
			{isModalVisible && (
				<PersologEvaluationClassModal
					onDismiss={handleModalOnDismiss}
					pageClassType={modalPageClassType}
					onPageClassTypeChange={setModalPageClassType}
					persologEvaluationLearnElementFragmentRef={element}
				/>
			)}
		</>
	);
};
