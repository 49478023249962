/**
 * @generated SignedSource<<5fca2bb45fb4730974a19c8bbf7eb10a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoteColor = "blue" | "default" | "green" | "red" | "yellow";
export type NoteSourceKind = "Course" | "Element" | "Module";
export type reminderOverview_RefetchQuery$variables = {
  filterByText?: string | null;
  includeNoteColors?: ReadonlyArray<NoteColor> | null;
  includeNoteSourceKinds?: ReadonlyArray<NoteSourceKind> | null;
  noteColorToSortBy?: NoteColor | null;
};
export type reminderOverview_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"reminderOverview_QueryFragment">;
};
export type reminderOverview_RefetchQuery = {
  response: reminderOverview_RefetchQuery$data;
  variables: reminderOverview_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByText"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeNoteColors"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeNoteSourceKinds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "noteColorToSortBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterByText",
    "variableName": "filterByText"
  },
  {
    "kind": "Variable",
    "name": "includeNoteColors",
    "variableName": "includeNoteColors"
  },
  {
    "kind": "Variable",
    "name": "includeNoteSourceKinds",
    "variableName": "includeNoteSourceKinds"
  },
  {
    "kind": "Variable",
    "name": "noteColorToSortBy",
    "variableName": "noteColorToSortBy"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reminderOverview_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "reminderOverview_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reminderOverview_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteQueries",
        "kind": "LinkedField",
        "name": "Note",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "NoteConnection",
            "kind": "LinkedField",
            "name": "Note",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Note",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "reminder",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCompleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "remindAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28a43af978afc1c586ef1e45c9c66a1c",
    "id": null,
    "metadata": {},
    "name": "reminderOverview_RefetchQuery",
    "operationKind": "query",
    "text": "query reminderOverview_RefetchQuery(\n  $filterByText: String\n  $includeNoteColors: [NoteColor!] = null\n  $includeNoteSourceKinds: [NoteSourceKind!] = null\n  $noteColorToSortBy: NoteColor\n) {\n  ...reminderOverview_QueryFragment_3vdupX\n}\n\nfragment reminderCard_ReminderNoteFragment on Note {\n  __typename\n  id\n  content\n  reminder {\n    __typename\n    kind\n    isCompleted\n    remindAt\n  }\n  ...upsertReminderModal_ReminderNoteFragment\n}\n\nfragment reminderForm_QueryFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    remindAt\n    isCompleted\n  }\n}\n\nfragment reminderOverview_QueryFragment_3vdupX on Query {\n  Note {\n    Note(includeNoteSourceKinds: $includeNoteSourceKinds, includeNoteColors: $includeNoteColors, noteColorToSortBy: $noteColorToSortBy, filterByText: $filterByText) {\n      edges {\n        node {\n          id\n          ...reminderCard_ReminderNoteFragment\n          ...reminderOverview_ReminderCardInlineFragment\n        }\n      }\n    }\n  }\n}\n\nfragment reminderOverview_ReminderCardInlineFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    isCompleted\n    remindAt\n  }\n}\n\nfragment upsertReminderModal_ReminderNoteFragment on Note {\n  id\n  ...reminderForm_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "7f3c1f7735b8b37436dd5c9448edc539";

export default node;
