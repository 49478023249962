import { ImageCardAtom } from "@thekeytechnology/academies-lib-webapp/components/image-card-atom";
import styled from "styled-components";
import { ReactComponent as IHK } from "@assets/IHK.svg";
import { TkaL1Span } from "@themes/font-tags";
import { spacing12, spacing16, spacing24, spacing4 } from "@themes/spacing";

export const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${spacing12.rem()};
`;

export const ImageTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${spacing24.rem()};
	position: relative;
`;

export const TaglineTitle = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	gap: ${spacing4.rem()};
`;

export const Tagline = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const IHKIcon = styled(IHK)`
	height: ${spacing16.rem()};
`;

export const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	flex: 1;
`;

export const StyledImage = styled(ImageCardAtom)`
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
`;

export const ProgressWrapper = styled.div`
	top: ${({ theme }) => theme.spacing.spacing8.rem()};
	right: ${({ theme }) => theme.spacing.spacing8.rem()};
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.spacing8.rem()};
	padding-block: ${({ theme }) => theme.spacing.spacing4.rem()};
	padding-left: ${({ theme }) => theme.spacing.spacing16.rem()};
	padding-right: ${({ theme }) => theme.spacing.spacing4.rem()};
	background-color: ${({ theme }) => theme.colors.onPrimary};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius10};
`;

export const GamificationPointsProgress = styled.div`
	display: flex;
	align-items: center;
	padding-block: ${({ theme }) => theme.spacing.spacing4.rem()};
	padding-inline: ${({ theme }) => theme.spacing.spacing8.rem()};
	gap: ${({ theme }) => theme.spacing.spacing4.rem()};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius20};
	background-color: ${({ theme }) => theme.colors.shade0};
	color: #e0a827;
`;

export const GamificationPointsProgressDivider = styled.div`
	height: 10px;
	width: 2px;
	border-radius: 1px;
	background-color: ${({ theme }) => theme.colors.shade10};
`;

export const PriceWrapper = styled.div`
	position: absolute;
	bottom: ${({ theme }) => theme.spacing.spacing8.rem()};
	right: ${({ theme }) => theme.spacing.spacing8.rem()};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.onPrimary};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius10.rem()};
	padding: ${({ theme }) => theme.spacing.spacing8.rem()};
`;

export const PriceSubtitle = styled(TkaL1Span)`
	color: ${({ theme }) => theme.colors.shade40};
`;
