import { DotProps } from "@components/carousel/parts/dot/dot.types";
import { onBrand, shade10 } from "@themes/colors";
import { spacing12, spacing8 } from "@themes/spacing";

export const Dot = ({ isActive, ...props }: DotProps) => (
	<span
		style={{
			display: "inline-block",
			height: isActive ? spacing12.rem() : spacing8.rem(),
			width: isActive ? spacing12.rem() : spacing8.rem(),
			background: isActive ? onBrand.getColor() : shade10.getColor(),
			borderRadius: "100%",
			transition: "0.3s ease all",
		}}
		{...props}
	></span>
);
